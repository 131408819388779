/**
 * Converts any angle to its canonical form in the range [0, 2π)
 * Optimized version that reduces operations and branching
 */
export function toCanonicalAngle(angle: number): number {
  return angle - Math.PI * 2 * Math.floor(angle / (Math.PI * 2));
}

/**
 * Normalizes an array of angles to their canonical form in range [0, 2π)
 * Uses chunking for better cache utilization and reduced operations
 */
export function normalizeToCanonicalAngles<
  T extends Float32Array | Float64Array
>(angles: T): T {
  const normalized = new (angles.constructor as new (length: number) => T)(
    angles.length
  );

  const CHUNK_SIZE = 1024; // Optimize for CPU cache
  const TWO_PI = Math.PI * 2;

  for (let offset = 0; offset < angles.length; offset += CHUNK_SIZE) {
    const limit = Math.min(offset + CHUNK_SIZE, angles.length);
    for (let i = offset; i < limit; i++) {
      normalized[i] = angles[i] - TWO_PI * Math.floor(angles[i] / TWO_PI);
    }
  }

  return normalized;
}
