/**
 * Converts any angle to a signed angle between -π and π
 * Optimized version that reduces operations and branching
 */
export function toSignedAngle(angle: number): number {
  angle -= Math.PI * 2 * Math.floor((angle + Math.PI) / (Math.PI * 2));
  return angle;
}

/**
 * Normalizes an array of angles to signed angles between -π and π
 * Uses chunking for better cache utilization and reduced operations
 */
export function normalizeSignedAngles<T extends Float32Array | Float64Array>(
  angles: T
): T {
  const normalized = new (angles.constructor as new (length: number) => T)(
    angles.length
  );
  const CHUNK_SIZE = 1024; // Optimize for CPU cache
  const TWO_PI = Math.PI * 2;

  for (let offset = 0; offset < angles.length; offset += CHUNK_SIZE) {
    const limit = Math.min(offset + CHUNK_SIZE, angles.length);
    for (let i = offset; i < limit; i++) {
      normalized[i] =
        angles[i] - TWO_PI * Math.floor((angles[i] + Math.PI) / TWO_PI);
    }
  }

  return normalized;
}
