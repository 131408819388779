import { ApolloError } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import gql from "graphql-tag";
import { useCallback } from "react";
import {
  EnrollSubscription,
  EnrollSubscriptionVariables,
} from "~/backend/graphql/EnrollSubscription";
import {
  Currency,
  PaymentPeriod,
  SubscriptionPlan,
} from "~/backend/graphql/globalTypes";
import { isBackendError } from "~/backend/utils/BackendError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { useWebSession } from "~/components/websession/WebSessionContext";
import { useErrorHandler } from "../../utils/useErrorHandler";
import { useMutationWithErrorHandling } from "../../utils/useMutationWithErrorHandling";
import { FRAGMENT_USER_INFO } from "../user/fragmentUserInfo";
import { FRAGMENT_BILLING_HISTORY } from "../user/useBillingHistory";
import { FRAGMENT_USER_BILLING_INFO } from "./useBillingInfo";
import { useUpdatePaymentMethod } from "./useUpdatePaymentMethod";

const ENROLL_SUBSCRIPTION = gql`
  mutation EnrollSubscription(
    $plan: SubscriptionPlan!
    $period: PaymentPeriod!
    $boats: Int!
    $currency: Currency!
    $affiliateCode: String
  ) {
    enrollSubscription(
      plan: $plan
      period: $period
      boats: $boats
      currency: $currency
      affiliateCode: $affiliateCode
    ) {
      subscribedUser {
        id

        ...UserInfo
        ...UserBillingInfo
        ...BillingHistory
      }
      stripeUserActionRequired
    }
  }
  ${FRAGMENT_USER_INFO}
  ${FRAGMENT_USER_BILLING_INFO}
  ${FRAGMENT_BILLING_HISTORY}
`;

interface IProps {
  onError: (e: string | undefined) => void;
  onSuccess: () => void | Promise<any>;
}

export const useSubscriptionFlow = ({ onSuccess, onError }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const doUpdatePaymentMethod = useUpdatePaymentMethod();
  const enrollSubscription = useMutationWithErrorHandling<
    EnrollSubscription,
    EnrollSubscriptionVariables
  >(ENROLL_SUBSCRIPTION, {});

  const subscriptionEvent = useAnalyticEvent("subscription-complete");
  const errorHandler = useErrorHandler(onError, "subscription-error");

  const affiliateCode = useWebSession().affiliateCode;

  return useCallback(
    async (
      plan: SubscriptionPlan,
      period: PaymentPeriod,
      boats: number,
      currency: Currency,
      amountShownUSD: number
    ) => {
      try {
        if (!stripe || !elements) {
          throw new Error(
            `Payment system not ready. Please try again in a few seconds.`
          );
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          throw new Error(`Card Element not found. Please contact us.`);
        }

        const paymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        if (paymentMethod.error) {
          throw paymentMethod.error;
        }

        await doUpdatePaymentMethod({
          variables: { paymentMethodId: paymentMethod.paymentMethod.id },
        });
        const subscriptionActivation = await enrollSubscription({
          variables: { plan, period, boats, currency, affiliateCode },
        });
        // If Stripe needs 3DS authorization, do it immediately
        // Note 2021-04, after one year of business I have yet to this happens even though it's a big pain to handle...
        if (
          subscriptionActivation?.enrollSubscription.stripeUserActionRequired
        ) {
          const confirm = await stripe.confirmCardPayment(
            subscriptionActivation.enrollSubscription.stripeUserActionRequired
          );
          if (confirm.error) {
            throw confirm.error;
          }
        }
        subscriptionEvent({
          amountUSD: amountShownUSD,
          plan: plan,
          withUserActionRequired:
            !!subscriptionActivation?.enrollSubscription
              .stripeUserActionRequired,
        });
        await onSuccess();
      } catch (error) {
        if (isBackendError(error as ApolloError, "daypass-notneeded")) {
          subscriptionEvent({
            eventName: "subscription-error",
            error: "daypass-notneeded",
          });
          await onSuccess();
        } else {
          errorHandler(error as Error);
        }
      }
    },
    [
      affiliateCode,
      doUpdatePaymentMethod,
      elements,
      enrollSubscription,
      errorHandler,
      onSuccess,
      stripe,
      subscriptionEvent,
    ]
  );
};
