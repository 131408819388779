import { Currency } from "../backend/graphql/globalTypes";

export const formatPrice = (v: number, currency: Currency = Currency.USD) => {
  // only show decimals when they are needed
  const formattedPrice = v % 1 === 0 ? v.toFixed(0) : v.toFixed(2);

  if (currency === Currency.USD) {
    return "$\u00A0" + formattedPrice;
  } else if (currency === "eur") {
    return `${formattedPrice}\u00A0€`;
  } else {
    return `${formattedPrice} ${currency}`;
  }
};
