import gql from "graphql-tag";

export const FRAGMENT_DAYPASS_OFFER = gql`
  fragment DayPassOffer on DayPassOffer {
    daypassType
    label
    description
    badge
    price
    currency
    priceLegend
    chargeDescription
    priceBeforeDiscount
    discountApplied
    discountDescription
  }
`;
export const FRAGMENT_SUBSCRIPTION_OFFER = gql`
  fragment SubscriptionOffer on SubscriptionOffer {
    plan
    paymentPeriod
    maxBoatsPerDay
    label
    description
    badge
    disabled
    freeTrialDays
    priceLegend
    price
    currency
    initialChargeDescription
    priceBeforeDiscount
    discountApplied
    discountDescription
  }
`;
