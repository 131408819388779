import {
  TrackPoint,
  TrackPointFieldsDescription,
} from "@chartedsails/sailing-data";
import {
  normalizeSignedAngles,
  normalizeToCanonicalAngles,
} from "@chartedsails/sailing-math";
import {
  filterBogusGPSPoints,
  InteractiveTrip,
  interactiveTripFromArrays,
  interpolateArraysValues,
  sogcogFromPositions,
} from "@chartedsails/tracks";
import { Table } from "apache-arrow";
import { sailingArraysFromTable } from "./arraysFromTable";

export const interactiveTripFromArrow = (
  table: Table,
  filterPointsAboveSOG?: number
): InteractiveTrip => {
  if (table.numRows === 0) {
    throw new Error("SailingDataTable is empty");
  }
  let sa = sailingArraysFromTable(table);
  if (filterPointsAboveSOG !== undefined) {
    sa = filterBogusGPSPoints(sa, filterPointsAboveSOG);
    // Calculate SOG and COG - only for the points we just removed
    sa = sogcogFromPositions(sa, false);
  }

  // Fill all the NaN in the data so we have 100% coverage of data
  sa = interpolateArraysValues(sa);

  if (!("sog" in sa) || !("cog" in sa)) {
    sa = sogcogFromPositions(sa);
  }

  // It helps when graphing and displaying to know that the values will be in the signed angle range
  Object.keys(sa).forEach((k) => {
    const key = k as keyof TrackPoint;
    if (TrackPointFieldsDescription[key]?.type === "relative-angle") {
      sa[k as "awa"] = normalizeSignedAngles(sa[k as "awa"] as Float32Array);
    }
  });
  Object.keys(sa).forEach((k) => {
    const key = k as keyof TrackPoint;
    if (TrackPointFieldsDescription[key]?.type === "angle") {
      sa[k as "cog"] = normalizeToCanonicalAngles(
        sa[k as "cog"] as Float32Array
      );
    }
  });

  const trip = interactiveTripFromArrays(sa);
  return trip;
};
