import { timeFormat } from "d3-time-format";
import spacetime from "spacetime";

type ValidDateTypes = Date | number | string;

const supportString = (fn: (d: Date) => string) => (d: ValidDateTypes) => {
  return typeof d === "string" ? fn(new Date(d)) : fn(d as Date);
};

export const formatDateShort = supportString(timeFormat("%m/%d/%Y"));
export const formatDateText = supportString(timeFormat("%B %d, %Y"));
export const formatDateTextWithDay = supportString(timeFormat("%A, %B %d"));
export const formatDateAndTime = supportString(
  timeFormat("%m/%d/%Y %-I:%M %p")
);

const spacetimeWithTimezone = (d: ValidDateTypes, tz?: string) => {
  let t = spacetime(d);
  if (tz) {
    t = t.goto(tz);
  } else {
    // Use computer timezone
    t = t.goto(null);
  }
  return t;
};

const timezoneAwareFormatter = (format: string) => {
  return (d: ValidDateTypes, tz?: string) => {
    return (
      spacetimeWithTimezone(d, tz).format(format) as string
    ).toUpperCase();
  };
};

export const formatTimeHHMM = timezoneAwareFormatter(
  "{hour-pad}:{minute-pad} {AMPM}"
);
export const formatTimeHHMMwithOffset = timezoneAwareFormatter(
  `{hour-pad}:{minute-pad} {AMPM} {offset}`
);

export const formatTimeHHMMSS = timezoneAwareFormatter(
  `{hour-pad}:{minute-pad}:{second-pad} {AMPM}`
);

export const formatTimeHHMMSSM = (d: ValidDateTypes, tz?: string) => {
  const t = spacetimeWithTimezone(d, tz);

  const millisecondMostSignificantDigit = Math.floor(t.millisecond() / 100);

  return (
    t.format(
      `{hour-pad}:{minute-pad}:{second-pad}.${millisecondMostSignificantDigit} {AMPM}`
    ) as string
  ).toUpperCase();
};
