import { PaymentPeriod } from "../backend/graphql/globalTypes";

export function formatPaymentPeriod(p: PaymentPeriod): string {
  switch (p) {
    case PaymentPeriod.ANNUAL:
      return "year";
    case PaymentPeriod.MONTHLY:
      return "month";
  }
}

export function displayPaymentPeriod(p?: PaymentPeriod): string {
  if (p) {
    return `/${formatPaymentPeriod(p)}`;
  } else {
    return "";
  }
}
