import { gql, useLazyQuery } from "@apollo/client";
import {
  GetSubscriptionOffer,
  GetSubscriptionOfferVariables,
} from "~/backend/graphql/GetSubscriptionOffer";
import {
  Currency,
  PaymentPeriod,
  SubscriptionPlan,
} from "~/backend/graphql/globalTypes";
import { useWebSession } from "~/components/websession/WebSessionContext";
import { FRAGMENT_SUBSCRIPTION_OFFER } from "./fragmentOffer";

const GET_SUBSCRIPTION_OFFER = gql`
  query GetSubscriptionOffer(
    $plan: SubscriptionPlan!
    $period: PaymentPeriod!
    $boats: Int!
    $currency: Currency!
    $affiliateCode: String
  ) {
    subscriptionOffer(
      plan: $plan
      period: $period
      boats: $boats
      currency: $currency
      affiliateCode: $affiliateCode
    ) {
      ...SubscriptionOffer
    }
  }
  ${FRAGMENT_SUBSCRIPTION_OFFER}
`;

export const useLazySubscriptionOffer = (
  plan: SubscriptionPlan,
  period: PaymentPeriod,
  boats: number,
  currency: Currency
) => {
  const affiliateCode = useWebSession().affiliateCode;

  return useLazyQuery<GetSubscriptionOffer, GetSubscriptionOfferVariables>(
    GET_SUBSCRIPTION_OFFER,
    { variables: { plan, period, boats, currency, affiliateCode } }
  );
};
