import { isRaceSegment } from "~/model/RaceSegment";
import { SailingInsights } from "~/model/SailingInsights";
import {
  boatCardEventReducer,
  ReplayBoatCardAction,
} from "./boatcard-event-reducer";
import { replayMakeAllTracksFitView } from "./helpers/makeAllTracksFitView";
import {
  keyboardEventReducer,
  ReplayKeyboardAction,
} from "./keyboard-event-reducer";
import { liveEventReducer, ReplayLiveAction } from "./live-reducer";
import {
  maneuverCardEventReducer,
  ReplayManeuverCardAction,
} from "./maneuvercard-event-reducer";
import { mapEventReducer, ReplayMapAction } from "./map-event-reducer";
import {
  RaceSetupAction,
  raceSetupEventReducer,
} from "./racesetup-event-reducer";
import { centerReplayOnBoat, updatePlaybackAnimation } from "./reducer-helpers";
import { Replay, ReplayBoat } from "./Replay";
import {
  ReplayChartAction,
  replayChartEventReducer,
} from "./replaychart-event-reducer";
import {
  ReplaySessionAction,
  sessionEventReducer,
} from "./session-event-reducer";
import { ReplayVideoAction, videoEventReducer } from "./video-event-reducer";

export type ReplayAction =
  // "Generic setter" for easier transition from the old state system

  // New action specific event - This is the new way.
  // Naming convention: 'componentsource-typeofaction[-target]
  // or actor-verb-target

  | { event: "animation-newframe"; autoLoop?: boolean }

  // Effects
  | { event: "effect-timeline-autozoom"; zoomSelection?: [number, number] }

  // Background stuff ...
  | {
      event: "background-sailinginsights-updated";
      sailingInsights: SailingInsights;
    }
  | { event: "import-changed"; boats: ReplayBoat[]; bounds: [number, number] }

  // Minireplay
  | { event: "minireplay-autostart" };

export const replayReducer = (
  replay: Replay,
  action:
    | ReplayAction
    | ReplayBoatCardAction
    | ReplayKeyboardAction
    | ReplayManeuverCardAction
    | ReplayLiveAction
    | ReplayMapAction
    | ReplaySessionAction
    | ReplayVideoAction
    | RaceSetupAction
    | ReplayChartAction
): Replay => {
  const originalReplay = replay;

  replay = keyboardEventReducer(replay, action as ReplayKeyboardAction);
  replay = maneuverCardEventReducer(replay, action as ReplayManeuverCardAction);
  replay = mapEventReducer(replay, action as ReplayMapAction);
  replay = boatCardEventReducer(replay, action as ReplayBoatCardAction);
  replay = sessionEventReducer(replay, action as ReplaySessionAction);
  replay = videoEventReducer(replay, action as ReplayVideoAction);
  replay = liveEventReducer(replay, action as ReplayLiveAction);

  if (replay.activePane === "racesetup") {
    replay = raceSetupEventReducer(replay, action as RaceSetupAction);
  } else if (replay.activePane === "replay-chart") {
    replay = replayChartEventReducer(replay, action as ReplayChartAction);
  }

  switch (action.event) {
    // Animation
    case "animation-newframe":
      if (replay.activeVideo) {
        // The video is controlling the replay. Do nothing.
        return replay;
      }
      let newPosition =
        replay.playbackAnimationStartPosition +
        (Date.now() - replay.playbackAnimationStartTime) * replay.playbackSpeed;

      let startTime = replay.startTime;
      let endTime = replay.endTime;
      if (replay.activeSegment) {
        startTime = replay.activeSegment.startTime;
        endTime = replay.activeSegment.endTime;

        if (
          isRaceSegment(replay.activeSegment) &&
          replay.selectedLegIndex !== undefined
        ) {
          const startAnalysis = replay.raceAnalysis?.start;
          const legAnalysis =
            replay.raceAnalysis?.legs[replay.selectedLegIndex];
          if (replay.selectedLegIndex === -1 && startAnalysis) {
            startTime = startAnalysis.startTime;
            endTime = startAnalysis.lastBoatAtLine ?? endTime;
          } else if (replay.selectedLegIndex !== undefined) {
            startTime = legAnalysis?.firstBoatInTime ?? startTime;
            endTime = legAnalysis?.lastBoatOutTime ?? endTime;
          }
        }
      }
      if (replay.timeSelection) {
        startTime = replay.timeSelection[0];
        endTime = replay.timeSelection[1];
      }
      let playing = replay.playing;
      if (newPosition >= endTime) {
        newPosition = startTime;
        playing = action.autoLoop ?? false;
      }
      replay = { ...replay, playing };

      if (replay.lockMapOnBoatId) {
        replay = centerReplayOnBoat(replay, replay.lockMapOnBoatId);
      }
      replay = updatePlaybackAnimation(replay, newPosition);
      break;

    // Background
    case "background-sailinginsights-updated":
      replay = { ...replay, sailingInsights: action.sailingInsights };
      break;
    // Effects
    case "effect-timeline-autozoom":
      replay = { ...replay, zoomSelection: action.zoomSelection };
      break;

    // Import
    case "import-changed":
      replay = replayMakeAllTracksFitView({
        ...replay,
        boats: action.boats,
        startTime: action.bounds[0],
        endTime: action.bounds[1],
      });
      break;

    // MiniReplay
    case "minireplay-autostart":
      replay = {
        ...updatePlaybackAnimation(replay, replay.playbackTime),
        playing: true,
      };
      break;
  }

  if (originalReplay === replay) {
    console.warn(`Unhandled reducer action ${action.event}`);
  }

  // sessionStorage.setItem('debugReplayReducer', true)
  // if (sessionStorage.getItem("debugReplayReducer") === "true") {
  //   console.log(
  //     `replay reducer event ${action.event} playbackTime=${replay.playbackTime}`,
  //     { replayBefore: originalReplay, replayAfter: replay, action }
  //   );
  // }

  return replay;
};
