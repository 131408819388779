import { Position } from "@chartedsails/sailing-data";
import { distance, rhumbBearing } from "@chartedsails/sailing-math";
import { SailingDataArray } from "./types";

/**
 * Takes a sailing array and re-calculates SOG/COG from the positions.
 *
 * If recalculateAllValues is true, then all values are recalculated, even if they are already present.
 * Otherwise, only missing values are calculated.
 *
 * @param sa
 * @param recalculateAllValues
 * @returns
 */
export const sogcogFromPositions = (
  sa: SailingDataArray,
  recalculateAllValues?: boolean
): SailingDataArray => {
  const len = sa.latitude.length;
  const sog = new Float32Array(len);
  const cog = new Float32Array(len);

  const useExisting = !recalculateAllValues && sa.sog && sa.cog;
  const CHUNK_SIZE = 1024; // Optimize for CPU cache line size

  // Process chunks
  for (let offset = 0; offset < len - 1; offset += CHUNK_SIZE) {
    const chunkEnd = Math.min(offset + CHUNK_SIZE, len - 1);

    // Process each chunk
    for (let i = offset; i < chunkEnd; i++) {
      if (
        useExisting &&
        Number.isFinite(sa.sog[i]) &&
        Number.isFinite(sa.cog[i])
      ) {
        sog[i] = sa.sog[i];
        cog[i] = sa.cog[i];
        continue;
      }

      const timeDiff = sa.time[i + 1] - sa.time[i];

      if (timeDiff <= 0) {
        sog[i] = i > 0 ? sog[i - 1] : 0;
        cog[i] = i > 0 ? cog[i - 1] : 0;
        continue;
      }

      const p1: Position = [sa.longitude[i], sa.latitude[i]];
      const p2: Position = [sa.longitude[i + 1], sa.latitude[i + 1]];

      if (!useExisting || !Number.isFinite(sa.sog[i])) {
        const d = distance(p1, p2);
        sog[i] = d / (timeDiff / 1000);
      } else {
        sog[i] = sa.sog[i];
      }

      if (!useExisting || !Number.isFinite(sa.cog[i])) {
        cog[i] = rhumbBearing(p1, p2);
      } else {
        cog[i] = sa.cog[i];
      }
    }
  }

  // Handle last element
  const lastIdx = len - 1;
  const prevIdx = len - 2;

  sog[lastIdx] =
    useExisting && Number.isFinite(sa.sog?.[lastIdx])
      ? sa.sog[lastIdx]
      : sog[prevIdx];

  cog[lastIdx] =
    useExisting && Number.isFinite(sa.cog?.[lastIdx])
      ? sa.cog[lastIdx]
      : cog[prevIdx];

  return { ...sa, sog, cog };
};
